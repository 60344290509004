
:root {
  --l1: #181818;
  --l2: #2a2a2f;
  --l3: #363636;
  --a1: #35a2eb;
  --a2: #7ccbe3;
  --a3: #088ee7;
  --d1: #f6faff;
}

/* :root {
  --l1: #fdf9f8;
  --l2: #f0e5df;
  --l3: #ebdad0;
  --a1: #df9788;
  --a2: #e39e7c;
  --a3: #d57f4c;
  --d1: #231f20;
} */

.v-veg {
  color: #C9EEA8;
  background-color: hsl(92, 67%, 30%);
}
.v-nonveg {
  color: #F6B4AA;
  background-color: hsl(8, 80%, 30%);
}
.v-egg {
  color: #EDE897;
  background-color: hsl(57, 70%, 30%);
}
.v-sea {
  color: #C4DFE3;
  background-color: hsl(188, 36%, 30%);
}
.v-default {
  color: var(--l2);
  background-color: var(--a3);
}

body {
  font-family: 'Lexend', sans-serif;
  font-weight: 200;
}

.splash {
  color: var(--a3);
  min-height: 100vh;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes appear {
  0% {
    opacity: 0%;
  }
  20% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes saturate-slide {
  from {
    filter: grayscale(100%);
    transform: translateY(-50px);
  }
  to {
    filter: grayscale(0%);
    transform: translateY(0px);
  }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes glow {
  0% { box-shadow: 0px 0px 20px var(--a3); }
  50% { box-shadow: 0px 0px 100px var(--a3); }
  100% { box-shadow: 0px 0px 20px var(--a3); }
}

.splash img {
  animation: appear 1s, glow 4s infinite;
  max-width: 60vh;
  align-self: center;
  box-shadow: 0px 0px 100px var(--a3);
  border-radius: 1000px;
  padding: 0;
}

.splash div div {
  animation: appear 4s, saturate-slide 2s;
}

.downarrow {
  animation: moveUpDown 2s infinite !important;
}

.typewriter {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  animation: appear 5s, typing 2s 1.25s steps(19, end) !important;
}
/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: var(--l2) 
}

::-webkit-scrollbar-thumb {
  background: var(--a1);
  border-radius: 10px 10px 10px 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--a3); 
}

.App-header {
  background-color: var(--l1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: var(--d1);
}

.catNav{
  background-image: -webkit-linear-gradient(transparent, var(--l1));
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.navButton {
  animation: appear 6s, saturate-slide 4s 1s;
  background-color: var(--l2) !important;
  color: var(--a3) !important;
  font-weight: 400;
  width: auto;
  white-space: nowrap;
}

.navButton:hover,
.navButton:focus,
.navButton:active,
.navButton.active {
  background-color: var(--a3) !important;
  color: var(--l2) !important;
}

/* Accordion */
.tab-content {
  min-height: 100vh;
}
.accordion {
  padding-bottom: 15vh;
}

.accordion-item {
  border: none !important;
}

.accordion-button {
  background-color: var(--l2);
  color: var(--d1);
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23088ee7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f0e5df'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: var(--a3);
  color: var(--l2);
  margin-top: 15px;
}

.accordion-body {
  background-color: var(--a3);
  margin-bottom: 15px;
}

.accordion-body .flex-row {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.accordion-body .flex-row::-webkit-scrollbar-track {
  background: var(--a3);
}

.accordion-body .flex-row::-webkit-scrollbar-thumb {
  background: var(--a2);
  border-radius: 10px 10px 10px 10px;
}

.accordion-body .flex-row::-webkit-scrollbar-thumb:hover {
  background: var(--l2); 
}

/* Cards & Pill */
.card {
  background-color: var(--l2);
  color: var(--d1);
  height: 33vh;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #00000050;
}

@media (max-width: 767px) {
  .card {
    width: 55vw;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .card {
    width: 30vw;
  }
}

@media (min-width: 992px) {
  .card {
    width: 25vw;
  }
}

.cardImageContainer {
  height: 18vh;
  overflow: hidden;
  border-radius: 10px 10px 50px 0px;
}

.pillContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.pillContainer .badge {
  font-weight: 400;
}

.pillContainer .badge span{
  padding-left: 5px;
  color: var(--d1);
  font-weight: 500;
}


/* div, li, ul, button {
  border: red 1px solid;
} */